import log from 'loglevel';
import SmartBranchingDecision from '../ExerciseNodes/SmartBranchingDecision';
import UserActionsEvaluator from '../UserActionsEvaluator';
import SpeechPartsHighlight from '../SpeechPartsHighlight';

export default class PromptBranchingDecision extends SmartBranchingDecision {
  m_GPTAnswer = null;
  m_DetectedUserActionsIDs = [];
  m_TriggeredUserActionsFeedbackInfos = [];
  m_UserActionEvaluator = null;
  m_SpeechPartsToHighlightData = {
    detectedUserActionsData: {},
    composedUserActionsData: {},
    userActionFeedbacksData: {}
  };

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    if (iProperties.EvaluatorRules && iProperties.EvaluatorRules.length) {
      // since the rules are stored as strings in the graph, they need to be parsed
      try {
        const parsedRules = iProperties.EvaluatorRules.map((rule) => JSON.parse(rule));
        this.m_UserActionEvaluator = new UserActionsEvaluator(parsedRules);
      } catch (error) {
        throw `PromptBranchingDecision: Failed to parse evaluator rules : ${iProperties.EvaluatorRules} at node ${this.ID}`;
      }
    }

    this.AvailableUserActions = this.MergeAvailableUserActionsData(
      iProperties.AvailableUserActions
    );
    this.AvailableUserActionsFeedbacks = this.MergeAvailableUserActionsFeedbacksData(
      iProperties.AvailableUserActionsFeedbacks
    );
  }

  MergeAvailableUserActionsData(iAvailableUserActions) {
    const mergedAvailableUserActions = {};

    for (const uaID of Object.keys(iAvailableUserActions)) {
      mergedAvailableUserActions[uaID] = this.Graph.GetFullUserActionData(uaID, this.ID, this);
    }

    return mergedAvailableUserActions;
  }

  MergeAvailableUserActionsFeedbacksData(iAvailableUserActionsFeedbacks) {
    const mergedAvailableUserActionsFeedbacks = {};

    for (const uafID of Object.keys(iAvailableUserActionsFeedbacks)) {
      mergedAvailableUserActionsFeedbacks[uafID] = this.Graph.GetFullUserActionFeedbackData(
        uafID,
        this.ID,
        this
      );
    }

    return mergedAvailableUserActionsFeedbacks;
  }

  async ExecuteAnalysis() {
    const thisAnalysisCounter = this.m_AnalysisCounter;
    log.debug(
      this.GetIdentity() +
        '.ExecuteAnalysis: Asking GPT for the ' +
        thisAnalysisCounter +
        'th time.'
    );

    // Initialize result
    let result = {
      status: 'failed',
      request: '',
      answer: '',
      branch: null,
      possibleBranches: JSON.stringify(this.Branches)
    };
    this.m_DetectedUserActionsIDs = [];
    this.m_SpeechPartsToHighlightData = {
      detectedUserActionsData: {},
      composedUserActionsData: {},
      userActionFeedbacksData: {}
    };

    // If in force user actions mode, do not ask GPT and wait for forced user actions
    if (window.testMode.forceUserActionsMode) {
      result.status = 'success';
      result.request = 'DEBUG';
      result.answer = 'DEBUG';
      await this.WaitForForcedUserActions();
    } else {
      // Ask user actions to GPT
      let userActionAnswer = null;
      const detectionInputData = {
        conversation: this.m_PreviousConversation,
        speech: this.m_Speech,
        exerciseId: this.Graph.ExerciseID,
        nodeId: this.ID
      };

      try {
        userActionAnswer = await window.sdk
          .openaiAPI()
          .CallDetectInteractionUserActionsAPI(
            detectionInputData.speech,
            detectionInputData.conversation,
            detectionInputData.exerciseId,
            detectionInputData.nodeId,
            this.DatabaseID
          );
      } catch (error) {
        if (error.name === 'AbortError') {
          throw error;
        } else {
          log.error(this.GetIdentity() + '.ExecuteAnalysis: Request failed:', error);
          result.status = 'failed';
          return result;
        }
      }

      // Log a warning for all failed UserAction detection queries
      for (let key of userActionAnswer.detection.failed) {
        log.warn(this.GetIdentity() + `.ExecuteAnalysis: User action detection failed for ${key}.`);
      }

      // Extract all positively detected UserAction IDs
      const userActionsIDs = Object.keys(userActionAnswer.detection.results).filter(
        (key) => userActionAnswer.detection.results[key] === true
      );

      // Update old analysis tasks status to "ignored" if existing
      if (this.previousUAAnalysisTaskID) {
        window.sdk.AnalysisTask().updateOne(this.DatabaseID, this.previousUAAnalysisTaskID, {
          AnalysisStatus: 'ignored',
          AnalyzerEngine: 'GPTUserAction'
        });
      }
      this.previousUAAnalysisTaskID = userActionAnswer.detection.analysisTaskID;

      // Prepare ouput result
      result.analysisTaskID = userActionAnswer.detection.analysisTaskID;
      result.answer = userActionsIDs;
      result.status = 'success';
      result.request = JSON.stringify(detectionInputData);

      // Seame log with a case where no user actions are detected
      if (userActionsIDs.length === 0) {
        log.debug(
          this.GetIdentity() +
            `.ExecuteAnalysis: No user actions detected. (Request counter ${thisAnalysisCounter})`
        );
      } else {
        log.debug(
          this.GetIdentity() +
            `.ExecuteAnalysis: User Action detection result =\n${result.answer
              .map((uaID) => this.AvailableUserActions[uaID].DisplayedName)
              .join('\n')}\n(Request counter ${thisAnalysisCounter})`
        );
      }

      // Extract detected user actions from user action IDs list
      this.ApplyDetectedUserActionsList(userActionsIDs);
      if (this.m_DetectedUserActionsIDs.length === 0) {
        log.debug(`${this.GetIdentity()}.ExecuteAnalysis: No user action detected.
          (Request counter ${thisAnalysisCounter})`);
        return result;
      }
    }

    // Extract branch choice from detected user actions and priority list
    const branchChoiceResults = this.ExtractChosenBranchFromUserActions();
    if (!branchChoiceResults) {
      log.debug(
        this.GetIdentity() +
          `.ExecuteAnalysis: Failed to choose a branch from detected user actions.
          (Request counter ${thisAnalysisCounter})`
      );
      return result;
    }

    // Get branch from answer and return it
    result.status = 'success';
    result.branch = branchChoiceResults.chosenBranch;
    result.prioritaryUserAction = branchChoiceResults.chosenUserAction.ID;

    log.debug(
      `${this.GetIdentity()}.ExecuteAnalysis: Finished!\n` +
        `Chosen branch =\n${JSON.stringify(result.branch, null, 1)}\n` +
        `From user action =\n"${result.prioritaryUserAction}"\n` +
        `Detected user actions =\n${JSON.stringify(this.m_DetectedUserActionsIDs, null, 1)}\n` +
        `(Request counter ${thisAnalysisCounter})`
    );

    return result;
  }

  async WaitForForcedUserActions() {
    log.debug(this.GetIdentity() + '.WaitForForcedUserActions: Waiting for forced user actions.');
    while (this.m_DetectedUserActionsIDs.length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
  }

  ApplyDetectedUserActionsList(iDetectedUserActionIDs) {
    this.m_DetectedUserActionsIDs = iDetectedUserActionIDs;

    // Save detected user actions data for speech highlight
    this.m_SpeechPartsToHighlightData.detectedUserActionsData = {
      userActionsIDs: iDetectedUserActionIDs,
      conversation: this.m_PreviousConversation
    };

    this.SolveComposedUserActions();
  }

  SolveComposedUserActions() {
    // If no rules to evaluate, return
    if (!this.m_UserActionEvaluator) {
      return;
    }

    const detectedUserActions = [...this.m_DetectedUserActionsIDs];
    let newUserActionsIDs = [];

    // Get user actions from rules
    const rulesResults = this.m_UserActionEvaluator.getUserActionIDsFromRules(
      this.m_DetectedUserActionsIDs
    );

    // Save composed user actions data for speech highlight
    this.m_SpeechPartsToHighlightData.composedUserActionsData = rulesResults;

    // Add user actions to the list of detected user actions
    for (const userActionID of Object.keys(rulesResults)) {
      let userAction = this.AvailableUserActions[userActionID] || null;

      if (!userAction) {
        log.error(
          `${this.GetIdentity()}.SolveComposedUserActions: User action ${userActionID} not found in AvailableUserActions.`
        );
        continue;
      }

      this.m_DetectedUserActionsIDs.push(userAction.ID);
      newUserActionsIDs.push(userAction.ID);
    }

    // Log composed user actions solver analysis to DynamoDB
    window.sdk
      .AnalysisTask()
      .createOne(
        this.DatabaseID, // Parent Branching Decision Node
        this.ID.toString(), // Node ID
        'ComposedUserActionsSolver', // analyzer Engine
        'NA', // Analyzer Version
        'raw', // Analysis Status
        JSON.stringify({
          Rules: this.m_UserActionEvaluator.rules,
          DetectedUserActions: detectedUserActions
        }), // Analysis Input
        this.m_BranchDetectionStartTime, // Start Time
        this.m_BranchDetectionDuration.toString(), // Analysis duration (milliseconds)
        'NA', // Possible choices
        JSON.stringify({
          NewUserActionsIDs: newUserActionsIDs,
          TotalUserActionsIDs: this.m_DetectedUserActionsIDs
        }), // Analysis Result
        this.Graph.ExerciseID.toString() // Exercise ID
      )
      .then((res) => {
        // Update old analysis tasks status to "ignored" if existing
        if (this.previousComposedUAAnalysisTaskID) {
          window.sdk
            .AnalysisTask()
            .updateOne(this.DatabaseID, this.previousComposedUAAnalysisTaskID, {
              AnalysisStatus: 'ignored',
              AnalyzerEngine: 'ComposedUserActionsSolver'
            });
        }
        this.previousComposedUAAnalysisTaskID = res.ID;
      });
  }

  ExtractChosenBranchFromUserActions() {
    // Get the highest priority user action from the detected user actions list
    let chosenUserActionID = '';
    let highestPriority = -1;

    for (let i = 0; i < this.m_DetectedUserActionsIDs.length; i++) {
      const userActionID = this.m_DetectedUserActionsIDs[i];

      // error protection to avoid crashing if user action is not found
      // This can happen as part of a very strange black magic bug,
      // only when we force user actions after a rewind...
      //
      // @TODO: investigate why this happens:
      // https://app.asana.com/0/1207608968872424/1208283048675294/f
      if (!this.AvailableUserActions[userActionID]) {
        continue;
      }

      const priority = this.AvailableUserActions[userActionID].PriorityRank;

      // If the user action has a higher priority than the current highest priority, update the chosen user action
      if (priority > highestPriority) {
        // If user action is found, update chosen user action
        chosenUserActionID = userActionID;
        highestPriority = priority;
      }
    }

    // Handle possible errors
    if (!chosenUserActionID) {
      log.error(
        `${this.GetIdentity()}.ExtractChosenBranchFromUserActions: Failed to choose a user action from priorities.
        DetectedUserActionsList = ${JSON.stringify(this.m_DetectedUserActionsIDs, null, 2)}`
      );
      return null;
    }
    if (!this.AvailableUserActions[chosenUserActionID]) {
      log.error(
        `${this.GetIdentity()}.ExtractChosenBranchFromUserActions: Failed, user action ${chosenUserActionID} does not exist in node's AvailableUserActions.
        AvailableUserActions = ${JSON.stringify(Object.keys(this.AvailableUserActions), null, 2)}`
      );
      return null;
    }

    // Get the chosen branch ID from the chosen user action
    let chosenBranchID = this.AvailableUserActions[chosenUserActionID].BranchID;

    return {
      chosenUserAction: this.AvailableUserActions[chosenUserActionID],
      chosenBranch: this.Branches[chosenBranchID]
    };
  }

  UseAnalysisResultModeSpecific() {
    if (!this.IgnoreUserActions) {
      this.DetectUserActionsGPT();
    }
  }

  async DetectUserActionsGPT() {
    this.m_UserActionsDetectionStartTime = new Date();

    // Push all detected actions to history and keep track of the toast ones to pop-up
    this.HandleUserActions();

    // Check all AvailableUserActions to check if the user missed opportunities
    this.DetectMissedOpportunities();

    // Make the user actions toasts pop in the UI
    this.MakeUserActionToastsPop();
  }

  HandleUserActions() {
    this.m_TriggeredUserActionsFeedbackInfos = [];

    // Push all detected actions to history and keep track of the strategic ones to pop-up
    for (let i = 0; i < this.m_DetectedUserActionsIDs.length; i++) {
      let userActionID = this.m_DetectedUserActionsIDs[i];

      // Get corresponding user action
      let userAction = this.AvailableUserActions[userActionID];

      if (!userAction) {
        log.debug(
          this.GetIdentity() +
            ".DetectUserActions: User action '" +
            userActionID +
            "' not found in graph."
        );
        continue;
      }

      // Add user action to history
      this.Graph.History.AddUserAction(
        this.ID,
        userAction.ID,
        this.Graph.GetCurrentActName(),
        this.DatabaseID
      );

      // Trigger user action feedback if found
      if (userAction.LinkedUserActionFeedback) {
        const linkedUserActionFeedbackID = userAction.LinkedUserActionFeedback;

        // Save user action feedback data for speech highlight
        if (
          !this.m_SpeechPartsToHighlightData.userActionFeedbacksData[linkedUserActionFeedbackID]
        ) {
          this.m_SpeechPartsToHighlightData.userActionFeedbacksData[linkedUserActionFeedbackID] = {
            sourceUserActionIDs: [userActionID]
          };
        } else {
          this.m_SpeechPartsToHighlightData.userActionFeedbacksData[
            linkedUserActionFeedbackID
          ].sourceUserActionIDs.push(userActionID);
        }

        // Add linked user action feedback to triggered list
        if (
          !this.m_TriggeredUserActionsFeedbackInfos.some(
            (item) => item.ID === linkedUserActionFeedbackID
          )
        ) {
          this.m_TriggeredUserActionsFeedbackInfos.push({
            ID: linkedUserActionFeedbackID,
            ShouldForceMissedOpportunity: userAction.ShouldForceMissedOpportunity
          });
        }
      }
    }

    // Handle User Actions Feedbacks from this.m_TriggeredUserActionsFeedbacks
    for (let triggeredUserActionFeedbackInfo of this.m_TriggeredUserActionsFeedbackInfos) {
      log.debug(
        this.GetIdentity() +
          '.DetectUserActions: Linked user action feedback found = ' +
          triggeredUserActionFeedbackInfo.ID
      );

      // Get user action feedback object
      const triggeredUserActionFeedback =
        this.AvailableUserActionsFeedbacks[triggeredUserActionFeedbackInfo.ID];
      if (!triggeredUserActionFeedback) {
        log.error(
          this.GetIdentity() +
            '.DetectUserActions: Linked user action feedback ' +
            triggeredUserActionFeedbackInfo.ID +
            ' not found in graph.'
        );
        continue;
      }

      // Add linked user action feedback to history
      const isMissedOpportunity = triggeredUserActionFeedbackInfo.ShouldForceMissedOpportunity;
      this.Graph.History.AddUserActionFeedback(
        this.ID,
        triggeredUserActionFeedbackInfo.ID,
        isMissedOpportunity,
        this.Graph.GetCurrentActName(),
        this.DatabaseID,
        this.Graph.GetCurrentSceneNodeID()
      );

      // Make it pop up
      if (triggeredUserActionFeedback.IsToast === true) {
        this.PushUserActionToast(triggeredUserActionFeedbackInfo.ID, isMissedOpportunity);
      }
    }

    // Detect speech parts to highlight
    SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights(
      this.Graph,
      this.Graph.History,
      this.Graph.ExerciseID,
      this.DatabaseID,
      {
        ...this.m_SpeechPartsToHighlightData // Copy to avoid synching with the original data
      }
    );
  }

  DetectMissedOpportunities() {
    // Check all AvailableUserActions to check if the user missed opportunities
    for (let userActionFeedback of Object.values(this.AvailableUserActionsFeedbacks)) {
      if (userActionFeedback.OpportunityAction === true) {
        // If userActionFeedback is not present in TriggeredUserActionsFeedbacks, it is missed
        if (!this.m_TriggeredUserActionsFeedbackInfos.includes(userActionFeedback.ID)) {
          log.debug(
            this.GetIdentity() +
              '.DetectMissedOpportunities: Missed opportunity = ' +
              userActionFeedback.ID
          );

          this.PushUserActionToast(userActionFeedback.ID, true);

          // Add missed opportunity to history
          this.Graph.History.AddMissedOpportunity(userActionFeedback.ID, this.ID, this.DatabaseID);
        }
      }
    }
  }

  async LogAnalysisResultToDynamoDB(iResult) {
    let status = 'raw';

    // Handle errors
    if (!iResult.answer || iResult.status !== 'success') {
      status = 'failed';
    }

    // Log branching decision analysis to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'BranchSolver', // analyzer Engine
      'NA', // Analyzer Version
      status, // Analysis Status
      JSON.stringify(
        {
          UserActions: iResult.answer,
          DetectionParametersUsed: iResult.request
        },
        null,
        2
      ), // Analysis Input
      this.m_BranchDetectionStartTime, // Start Time
      this.m_BranchDetectionDuration.toString(), // Analysis duration (milliseconds)
      iResult.possibleBranches, // Possible choices
      JSON.stringify(
        {
          'Final choice': iResult.branch,
          PrioritaryUserAction: iResult.prioritaryUserAction
        },
        null,
        2
      ), // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );
  }

  // Test mode: force user actions and wait for them
  ForceUserActions(iUserActionIDs) {
    log.debug(
      this.GetIdentity() + '.ForceUserActions: Forcing user actions detection of: ',
      iUserActionIDs
    );
    log.debug('TestCreation[ForceUA] ', iUserActionIDs);
    this.ApplyDetectedUserActionsList(iUserActionIDs);
  }

  Reset() {
    this.m_GPTAnswer = null;
    super.Reset();
  }
}
